/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios from "axios";
import { BASE_URL } from "@/config";
import { useMutation } from "@tanstack/react-query";

export const BASE_FILE_URL =
  import.meta.env.VITE_NODE_ENV === "development"
    ? import.meta.env.VITE_DEV_FILE_API_URL
    : import.meta.env.VITE_NODE_ENV === "test"
    ? import.meta.env.VITE_TEST_FILE_API_URL
    : import.meta.env.VITE_NODE_ENV === "production"
    ? import.meta.env.VITE_PROD_FILE_API_URL
    : null;

export const UPLOAD_FILE = async (data: FormData) => {
  try {
    const response = await Axios.post(`${BASE_FILE_URL}/upload`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return response.data;
  } catch (e: any) {
    // Return a more detailed error
    throw new Error(e.response?.data?.message || e.message);
  }
};
